import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "disabled", "controlClassName", "required", "placeholder", "minDate", "maxDate", "todayButtonText", "popperPlacement", "value", "locale", "showCalendar", "isInitiallyOpen", "startDate", "selectsStart", "endDate", "selectsEnd", "name", "registerValidation", "autoComplete", "className", "onBlur", "onFocus", "readOnly", "openWithIconOnly", "inputFormat", "noMask", "displayFormat", "onCalendarOpen", "onCalendarClose", "testId"];
import "core-js/modules/es.string.replace.js";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import { isValid } from "date-fns";
import { formatDateToDataType, parseDateFromDataType, formatDateToLocalePattern, trimToInputFormat } from "@jutro/locale";
import isArray from "lodash/isArray";
import { messages } from "./DateField.messages";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { CustomHeader } from "./CustomHeader";
import { DateValue } from "../../../values/DateValue";
import { DateFieldValidationImplementation } from "./DateFieldValidationImplementation";
import { OldDateFieldValidationImplementation } from "./OldDateFieldValidationImplementation";
import { GenericDatePicker } from "../GenericDatePicker/GenericDatePicker";
export var MIN_DEFAULT_YEAR = 1900;
export var MAX_DEFAULT_YEAR = 2100;
export var DateFieldInternal = function (_FieldComponent) {
  _inherits(DateFieldInternal, FieldComponent);
  var _super = _createSuper(DateFieldInternal);
  function DateFieldInternal(_props) {
    var _thisSuper, _thisSuper2, _this;
    _classCallCheck(this, DateFieldInternal), _this = _super.call(this, _props), _defineProperty(_assertThisInitialized(_this), "datePickerRef", void 0), _defineProperty(_assertThisInitialized(_this), "userInput", void 0), _defineProperty(_assertThisInitialized(_this), "ongoingUserInput", !1), _defineProperty(_assertThisInitialized(_this), "validationImplementation", void 0), _defineProperty(_assertThisInitialized(_this), "state", {
      isFocused: _this.state.isFocused,
      focused: _this.focused,
      isValidDate: !0
    }), _defineProperty(_assertThisInitialized(_this), "validationDependencyProps", ["minDate", "maxDate", "minYear", "maxYear"]), _defineProperty(_assertThisInitialized(_this), "formatValue", function (value) {
      var dataType = _this.props.dataType;
      return trimToInputFormat(formatDateToDataType(value, dataType), _this.getFormat());
    }), _defineProperty(_assertThisInitialized(_this), "handleDateChange", function (date) {
      _this.validationImplementation.handleDateChange(date);
    }), _defineProperty(_assertThisInitialized(_this), "handleChangeRaw", function (evt) {
      _this.validationImplementation.handleChangeRaw(evt);
    }), _defineProperty(_assertThisInitialized(_this), "renderCustomHeader", function (props) {
      var _this$props = _this.props,
        minDate = _this$props.minDate,
        maxDate = _this$props.maxDate,
        locale = _this$props.locale,
        min = minDate ? parseDateFromDataType(minDate).getUTCFullYear() : MIN_DEFAULT_YEAR,
        max = maxDate ? parseDateFromDataType(maxDate).getUTCFullYear() : MAX_DEFAULT_YEAR;
      return React.createElement(CustomHeader, _extends({}, props, {
        locale: locale,
        minYear: min,
        maxYear: max
      }));
    }), _defineProperty(_assertThisInitialized(_this), "handleFocus", function (evt) {
      _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(DateFieldInternal.prototype)), "handleFocus", _thisSuper).call(_thisSuper, evt), _this.setState({
        focused: _this.focused
      });
    }), _defineProperty(_assertThisInitialized(_this), "handleBlur", function (evt) {
      _get((_thisSuper2 = _assertThisInitialized(_this), _getPrototypeOf(DateFieldInternal.prototype)), "handleBlur", _thisSuper2).call(_thisSuper2, evt), _this.setState({
        focused: _this.focused
      });
    }), _defineProperty(_assertThisInitialized(_this), "setFocus", function () {
      var _this$datePickerRef, _this$datePickerRef$c;
      null === (_this$datePickerRef = _this.datePickerRef) || void 0 === _this$datePickerRef || null === (_this$datePickerRef$c = _this$datePickerRef.current) || void 0 === _this$datePickerRef$c || _this$datePickerRef$c.setFocus();
    }), _this.datePickerRef = React.createRef();
    var ValidationImplementation = _props.registerValidation ? new DateFieldValidationImplementation() : new OldDateFieldValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(DateFieldInternal, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(DateFieldInternal.prototype), "render", this).call(this);
    }
  }, {
    key: "getValue",
    value: function (value) {
      return this.validationImplementation.getValue(value);
    }
  }, {
    key: "getFormat",
    value: function () {
      var format = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.props.inputFormat;
      return isArray(format) ? format[0] : format;
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      var _this$validationImple;
      return (_this$validationImple = this.validationImplementation).getValidationMessages.apply(_this$validationImple, arguments);
    }
  }, {
    key: "getValidationConfig",
    value: function () {
      var propsList;
      return propsList = this.props.showYearPicker ? ["inputFormat", "maxYear", "minYear"] : ["inputFormat", "maxDate", "minDate"], _get(_getPrototypeOf(DateFieldInternal.prototype), "getValidationConfig", this).call(this, {
        propsList: propsList,
        options: {
          locale: this.props.locale,
          displayFormat: this.props.displayFormat,
          localePattern: this.getFormat(),
          intl: this.props.intl
        }
      });
    }
  }, {
    key: "getPlaceholderOrDefault",
    value: function (placeholder) {
      var translatedLocalPattern = this.getFormat().replace("dd", this.translator(messages.dd)).replace("MM", this.translator(messages.mm)).replace("yyyy", this.translator(messages.yy));
      return placeholder ? this.translator(placeholder, {
        format: translatedLocalPattern
      }) : translatedLocalPattern;
    }
  }, {
    key: "getInputValue",
    value: function () {
      var _this$props2 = this.props,
        value = _this$props2.value,
        displayFormat = _this$props2.displayFormat,
        intl = _this$props2.intl,
        _this$state = this.state,
        focused = _this$state.focused,
        userInput = _this$state.userInput,
        selectedDate = value && parseDateFromDataType(value);
      return focused || void 0 !== userInput || selectedDate && !isValid(selectedDate) ? userInput : formatDateToLocalePattern(selectedDate, null == intl ? void 0 : intl.formatDate, displayFormat, this.getFormat());
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        displayFormat = breakpointProps.displayFormat,
        inputFormat = breakpointProps.inputFormat;
      return React.createElement(DateValue, _extends({
        id: id,
        value: value,
        displayFormat: displayFormat,
        inputFormat: this.getFormat(inputFormat),
        skipTimeZone: !0
      }, this.generateReadOnlyAccessibilityProperties()));
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var _this2 = this,
        _ref = breakpointProps,
        id = _ref.id,
        disabled = _ref.disabled,
        controlClassName = _ref.controlClassName,
        required = _ref.required,
        placeholder = _ref.placeholder,
        minDate = _ref.minDate,
        maxDate = _ref.maxDate,
        todayButtonText = _ref.todayButtonText,
        popperPlacement = _ref.popperPlacement,
        value = _ref.value,
        locale = _ref.locale,
        showCalendar = _ref.showCalendar,
        isInitiallyOpen = _ref.isInitiallyOpen,
        startDate = _ref.startDate,
        selectsStart = _ref.selectsStart,
        endDate = _ref.endDate,
        selectsEnd = _ref.selectsEnd,
        name = _ref.name,
        registerValidation = _ref.registerValidation,
        openWithIconOnly = (_ref.autoComplete, _ref.className, _ref.onBlur, _ref.onFocus, _ref.readOnly, _ref.openWithIconOnly),
        inputFormat = _ref.inputFormat,
        noMask = _ref.noMask,
        _onCalendarOpen = (_ref.displayFormat, _ref.onCalendarOpen),
        _onCalendarClose = _ref.onCalendarClose,
        testId = _ref.testId,
        other = _objectWithoutProperties(_ref, _excluded),
        minDateObject = minDate ? parseDateFromDataType(minDate) : null,
        maxDateObject = maxDate ? parseDateFromDataType(maxDate) : null,
        validationMessages = this.getValidationMessages(value),
        isRequired = null != required ? required : void 0,
        isDisabled = null != disabled ? disabled : void 0,
        selectedDate = value ? parseDateFromDataType(value) : void 0,
        selectedStartDate = startDate ? parseDateFromDataType(startDate) : void 0,
        selectedEndDate = endDate ? parseDateFromDataType(endDate) : void 0,
        icon = breakpointProps.icon;
      return React.createElement(GenericDatePicker, _extends({
        id: this.fieldUniqueId,
        labelId: options.labelId,
        icon: icon,
        ref: this.datePickerRef,
        selected: "object" === _typeof(selectedDate) && isValid(selectedDate) ? selectedDate : null,
        value: this.getInputValue(),
        onChange: this.handleDateChange,
        onChangeRaw: this.handleChangeRaw,
        onSelect: this.setFocus,
        todayButtonText: todayButtonText,
        className: null != controlClassName ? controlClassName : void 0,
        locale: locale.code,
        minDate: minDateObject,
        maxDate: maxDateObject,
        popperPlacement: popperPlacement,
        popperModifiers: [{
          name: "preventOverflow",
          enabled: !0
        }, {
          name: "flip",
          options: {
            allowedAutoPlacements: ["bottom"]
          }
        }],
        inputFormat: this.getFormat(inputFormat),
        preventOpenOnFocus: !0,
        openWithIconOnly: openWithIconOnly,
        showCalendar: showCalendar,
        renderCustomHeader: this.renderCustomHeader,
        disabled: isDisabled,
        required: Boolean(isRequired),
        name: name,
        testId: null != testId ? testId : id,
        placeholderText: this.getPlaceholderOrDefault(placeholder),
        showMask: !noMask && this.state.focused,
        noMask: noMask
      }, this.generateAccessibilityProperties(), this.generateDataPathProperty(), this.getInputFocusHandlers(), {
        startOpen: isInitiallyOpen,
        onCalendarOpen: function () {
          _this2.setState({
            focused: !0
          });
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
          null == _onCalendarOpen || _onCalendarOpen.apply(void 0, args);
        },
        onCalendarClose: function () {
          _this2.setState({
            focused: !1
          });
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) args[_key2] = arguments[_key2];
          null == _onCalendarClose || _onCalendarClose.apply(void 0, args);
        },
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        selectsStart: selectsStart,
        selectsEnd: selectsEnd,
        validationMessages: validationMessages,
        isValid: null == options ? void 0 : options.isValid,
        registerValidation: registerValidation
      }, other));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function (props, state) {
      return props.value === state.prevValue ? null : {
        prevValue: props.value,
        isValidDate: !0,
        userInput: void 0
      };
    }
  }]), DateFieldInternal;
}();
_defineProperty(DateFieldInternal, "displayName", "DateFieldInternal"), DateFieldInternal.__docgenInfo = {
  description: "Allows the user to either type or select a date. Displays them according to locale settings.\n\n@metadataType field",
  methods: [{
    name: "getValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      optional: !0,
      type: {
        name: "DateValueShape",
        alias: "DateValueShape"
      }
    }],
    returns: {
      type: {
        name: "DateValueShape"
      }
    }
  }, {
    name: "getFormat",
    docblock: null,
    modifiers: [],
    params: [{
      name: "format",
      type: null
    }],
    returns: {
      type: {
        name: "string"
      }
    }
  }, {
    name: "formatValue",
    docblock: "Convert the date object returned by the datepicker to\nthe expected format based on dataType props.\n\n@param {object} [value] - date object\n@returns {object} - string or date object, always a UTC date",
    modifiers: [],
    params: [{
      name: "value",
      type: {
        name: "Date",
        alias: "Date"
      }
    }],
    returns: {
      type: {
        name: "Partial",
        elements: [{
          name: "DateValueShape"
        }],
        raw: "Partial<DateValueShape>"
      }
    },
    description: "Convert the date object returned by the datepicker to\nthe expected format based on dataType props."
  }, {
    name: "handleDateChange",
    docblock: "Handles user triggered date changes\n\n@param {Date} [date] the date that the value was changed to",
    modifiers: [],
    params: [{
      name: "date",
      type: {
        name: "Date",
        alias: "Date"
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    },
    description: "Handles user triggered date changes"
  }, {
    name: "handleChangeRaw",
    docblock: "Called with a value when the input is edited manually or date is selected\n\n@param {object} [evt] - the event that triggers the change",
    modifiers: [],
    params: [{
      name: "evt",
      type: {
        name: "ReactChangeEvent",
        raw: "React.ChangeEvent<HTMLInputElement>",
        elements: [{
          name: "HTMLInputElement"
        }]
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    },
    description: "Called with a value when the input is edited manually or date is selected"
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [{
      name: "...args",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }],
        raw: "any[]"
      }
    }],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "getPlaceholderOrDefault",
    docblock: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale.\n\n@param {intlMessageShape} [placeholder] placeholder provided by props\n@returns {string} placeholder",
    modifiers: [],
    params: [{
      name: "placeholder",
      type: {
        name: "union",
        raw: "IntlMessageShape | undefined | null",
        elements: [{
          name: "IntlMessageShape"
        }, {
          name: "undefined"
        }, {
          name: "null"
        }]
      }
    }],
    returns: {
      type: {
        name: "string"
      }
    },
    description: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale."
  }, {
    name: "getInputValue",
    docblock: "Format displaying date using Intl",
    modifiers: [],
    params: [],
    returns: {
      type: {
        name: "union",
        raw: "string | undefined",
        elements: [{
          name: "string"
        }, {
          name: "undefined"
        }]
      }
    },
    description: "Format displaying date using Intl"
  }, {
    name: "renderCustomHeader",
    docblock: "Renders custom header.\n\n@param {object} [props] - props for custom header\n\n@returns {React.ReactElement} - the resulting rendered header",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: null,
    description: "Renders custom header."
  }, {
    name: "handleFocus",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: {
        name: "ReactFocusEvent",
        raw: "React.FocusEvent<HTMLInputElement>",
        elements: [{
          name: "HTMLInputElement"
        }]
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    }
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: {
        name: "ReactFocusEvent",
        raw: "React.FocusEvent<HTMLInputElement>",
        elements: [{
          name: "HTMLInputElement"
        }]
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    }
  }, {
    name: "setFocus",
    docblock: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header",
    modifiers: [],
    params: [],
    returns: {
      type: {
        name: "void"
      }
    },
    description: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "DateFieldInternalProps",
        alias: "DateFieldInternalProps"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "renderControl",
    docblock: "Render the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} [options] - some options?\n@returns {React.ReactElement} - The resulting rendered DateField",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "DateFieldInternalProps",
        alias: "DateFieldInternalProps"
      },
      optional: !1
    }, {
      name: "options",
      type: {
        name: "signature",
        type: "object",
        raw: "{ labelId?: string; isValid?: boolean }",
        signature: {
          properties: [{
            key: "labelId",
            value: {
              name: "string",
              required: !1
            }
          }, {
            key: "isValid",
            value: {
              name: "boolean",
              required: !1
            }
          }]
        }
      }
    }],
    returns: {
      type: {
        name: "ReactElement"
      }
    },
    description: "Render the control"
  }],
  displayName: "DateFieldInternal"
}, DateFieldInternal.__docgenInfo = {
  componentName: "DateFieldInternal",
  packageName: "@jutro/components",
  description: "Allows the user to either type or select a date. Displays them according to locale settings.",
  displayName: "DateFieldInternal",
  methods: [{
    name: "getValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getFormat",
    docblock: null,
    modifiers: [],
    params: [{
      name: "format",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "formatValue",
    docblock: "Convert the date object returned by the datepicker to\nthe expected format based on dataType props.\n\n@param {object} [value] - date object\n@returns {object} - string or date object, always a UTC date",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Convert the date object returned by the datepicker to\nthe expected format based on dataType props."
  }, {
    name: "handleDateChange",
    docblock: "Handles user triggered date changes\n\n@param {Date} [date] the date that the value was changed to",
    modifiers: [],
    params: [{
      name: "date"
    }],
    returns: null,
    description: "Handles user triggered date changes"
  }, {
    name: "handleChangeRaw",
    docblock: "Called with a value when the input is edited manually or date is selected\n\n@param {object} [evt] - the event that triggers the change",
    modifiers: [],
    params: [{
      name: "evt"
    }],
    returns: null,
    description: "Called with a value when the input is edited manually or date is selected"
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "getPlaceholderOrDefault",
    docblock: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale.\n\n@param {intlMessageShape} [placeholder] placeholder provided by props\n@returns {string} placeholder",
    modifiers: [],
    params: [{
      name: "placeholder"
    }],
    returns: null,
    description: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale."
  }, {
    name: "getInputValue",
    docblock: "Format displaying date using Intl",
    modifiers: [],
    params: [],
    returns: null,
    description: "Format displaying date using Intl"
  }, {
    name: "renderCustomHeader",
    docblock: "Renders custom header.\n\n@param {object} [props] - props for custom header\n\n@returns {React.ReactElement} - the resulting rendered header",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: null,
    description: "Renders custom header."
  }, {
    name: "handleFocus",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "setFocus",
    docblock: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header",
    modifiers: [],
    params: [],
    returns: null,
    description: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "renderControl",
    docblock: "Render the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} [options] - some options?\n@returns {React.ReactElement} - The resulting rendered DateField",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options"
    }],
    returns: null,
    description: "Render the control"
  }],
  actualName: "DateFieldInternal",
  metadataType: "field"
};
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import "core-js/modules/es.string.replace.js";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useAuthState } from "./AuthStateProvider";
export var LoginCallback = function (_ref) {
  var ErrorComponent = _ref.errorComponent,
    error = useAuth().error,
    _useAuthState = useAuthState(),
    oktaAuth = _useAuthState.oktaAuth,
    setLoginState = _useAuthState.setLoginState,
    history = useHistory();
  return useEffect(function () {
    var hasUnmounted = !1;
    setLoginState("pending");
    var _ref2,
      loginRedirect = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
        return _regeneratorRuntime.wrap(function (_context) {
          for (;;) switch (_context.prev = _context.next) {
            case 0:
              if (!oktaAuth.isLoginRedirect()) {
                _context.next = 11;
                break;
              }
              return _context.prev = 1, _context.next = 4, oktaAuth.handleLoginRedirect();
            case 4:
              _context.next = 9;
              break;
            case 6:
              return _context.prev = 6, _context.t0 = _context.catch(1), _context.abrupt("return", {
                error: _context.t0
              });
            case 9:
              _context.next = 12;
              break;
            case 11:
              history.replace("/");
            case 12:
              return _context.abrupt("return", "loggedIn");
            case 13:
            case "end":
              return _context.stop();
          }
        }, _callee, null, [[1, 6]]);
      })), function () {
        return _ref2.apply(this, arguments);
      });
    return loginRedirect().then(function (result) {
      hasUnmounted || setLoginState(result);
    }), function () {
      hasUnmounted = !0;
    };
  }, [history, oktaAuth, setLoginState]), error ? React.createElement(ErrorComponent, {
    error: error
  }) : null;
};
LoginCallback.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LoginCallback"
}, LoginCallback.__docgenInfo = {
  componentName: "LoginCallback",
  packageName: "@jutro/auth",
  description: "",
  displayName: "LoginCallback",
  methods: [],
  actualName: "LoginCallback"
};
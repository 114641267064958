import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import "core-js/modules/es.string.replace.js";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { useContext } from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import cx from "classnames";
import { isValid as isValidDate, parse as parseDate, format as formatDate } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { LocaleContext } from "@jutro/locale";
import { getMessageProp } from "@jutro/platform";
import { DateValue } from "../../../values/DateValue";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { FieldIcon } from "../../FieldComponent/FieldIcon";
import { GenericSelectControl } from "../../GenericSelectControl/GenericSelectControl";
import { isAccessibleDisabled, muteCallbacks } from "../../../../accessibleDisabled";
import { generateTimeIntervals, filterOptions, isInRange } from "./helpers";
import { messages } from "./TimeField.messages";
import styles from "./TimeField.module.css";
var FORMAT_12H = {
    type: "12h",
    format: "hh:mm a",
    placeholder: "12:00 AM",
    mask: "Hh:Mm aa"
  },
  FORMAT_24H = {
    type: "24h",
    format: "HH:mm",
    placeholder: "00:00",
    mask: "Hh:Mm"
  },
  TimeFieldInternal = function (_FieldComponent) {
    _inherits(TimeFieldInternal, FieldComponent);
    var _super = _createSuper(TimeFieldInternal);
    function TimeFieldInternal() {
      var _context, _this;
      _classCallCheck(this, TimeFieldInternal);
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "isValidOption", function (value, selectValue, selectOptions) {
        var timeFormat = _this.props.timeFormat,
          format = _this.getTimeFormat(timeFormat).format,
          isValid = isValidDate(parseDate(value, format, new Date())),
          isExist = _findInstanceProperty(selectOptions).call(selectOptions, function (option) {
            return option.value.toLowerCase() === value.toLowerCase();
          });
        return isValid && !isExist;
      }), _defineProperty(_assertThisInitialized(_this), "onValueChange", function (option) {
        if (option) {
          var format = _this.getTimeFormat(_this.props.timeFormat).format,
            parsedValue = parseDate(option.value, format, new Date());
          _this.notifyChange({
            hour: parsedValue.getHours(),
            minute: parsedValue.getMinutes()
          });
        } else _this.notifyChange(void 0);
      }), _this;
    }
    return _createClass(TimeFieldInternal, [{
      key: "getPlaceholder",
      value: function (customPlaceholder) {
        if (customPlaceholder) return (0, this.translator)(customPlaceholder);
        var timeFormat = this.props.timeFormat;
        return this.getTimeFormat(timeFormat).placeholder;
      }
    }, {
      key: "getTimeFormat",
      value: function (timeFormat) {
        var _context2,
          locale = this.props.locale;
        return timeFormat === FORMAT_12H.type ? FORMAT_12H : timeFormat === FORMAT_24H.type ? FORMAT_24H : _findInstanceProperty(_context2 = [FORMAT_12H, FORMAT_24H]).call(_context2, function (_ref) {
          var _locale$formatLong,
            format = _ref.format;
          return _includesInstanceProperty(format).call(format, null === (_locale$formatLong = locale.formatLong) || void 0 === _locale$formatLong ? void 0 : _locale$formatLong.time({
            width: "short"
          }));
        }) || FORMAT_24H;
      }
    }, {
      key: "getValidationMessages",
      value: function () {
        var value = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.getValue(),
          _this$props = this.props,
          messageProps = _this$props.messageProps,
          min = _this$props.min,
          max = _this$props.max,
          isValid = isInRange(value, min, max),
          incorrectInput = getMessageProp("incorrectInput", messageProps, messages),
          validationMessages = _get(_getPrototypeOf(TimeFieldInternal.prototype), "getValidationMessages", this).call(this) || [];
        return !isValid && isEmpty(validationMessages) ? [this.translator(incorrectInput)] : validationMessages;
      }
    }, {
      key: "parseRawValue",
      value: function (value) {
        if (!value) return "";
        var timeFormat = this.props.timeFormat,
          format = this.getTimeFormat(timeFormat).format,
          hour = value.hour,
          minute = value.minute,
          date = new Date(1970, 0, 1, hour, minute, 0);
        return formatDate(date, format);
      }
    }, {
      key: "parseValue",
      value: function (value) {
        if (value) {
          var timeFormat = this.props.timeFormat,
            format = this.getTimeFormat(timeFormat).format,
            hour = value.hour,
            minute = value.minute,
            date = new Date(1970, 0, 1, hour, minute, 0);
          return {
            value: formatDate(date, format),
            label: formatDate(date, format)
          };
        }
      }
    }, {
      key: "renderInput",
      value: function (inputProps, accessibleDisabled) {
        var _this2 = this,
          _this$props2 = this.props,
          value = _this$props2.value,
          disabled = _this$props2.disabled,
          placeholder = _this$props2.placeholder,
          timeFormat = _this$props2.timeFormat,
          label = _this$props2.label,
          id = _this$props2.id,
          testId = _this$props2.testId,
          _this$getTimeFormat6 = this.getTimeFormat(timeFormat),
          mask = _this$getTimeFormat6.mask,
          timeFormatType = _this$getTimeFormat6.type,
          fieldUniqueId = this.fieldUniqueId,
          ariaAutocomplete = inputProps["aria-autocomplete"],
          inputValue = inputProps.value,
          onFocus = inputProps.onFocus,
          onBlur = inputProps.onBlur,
          onChange = inputProps.onChange,
          formatChars = _objectSpread({
            H: "[0-2]",
            h: "[0-9]",
            M: "[0-5]",
            m: "[0-9]",
            a: "[AM|PM|am|pm]"
          }, "12h" === timeFormat ? _startsWithInstanceProperty(inputValue).call(inputValue, "1") ? {
            H: "[0-1]",
            h: "[0-2]"
          } : {
            H: "[0-1]"
          } : _startsWithInstanceProperty(inputValue).call(inputValue, "2") ? {
            h: "[0-3]"
          } : {}),
          selectedValue = this.parseRawValue(value),
          classes = cx(styles.input, {
            disabled: disabled
          }),
          onChangeCallback = function (event) {
            var eventValue = event.target.value;
            eventValue === selectedValue || eventValue.replace("/_|s|:/", "") || _this2.onValueChange(), onChange(event);
          },
          ariaLabel = "12h" === timeFormatType ? messages.inputAriaLabel12 : messages.inputAriaLabel24,
          accessibleDisabledInputProps = accessibleDisabled ? {
            "aria-disabled": !0,
            "aria-readonly": !0,
            readOnly: !0,
            disabled: void 0,
            "aria-label": this.translator(label)
          } : {},
          accessibleDisabledInputMaskProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
            onChange: onChangeCallback,
            onFocus: onFocus,
            onBlur: onBlur
          })), accessibleDisabledInputProps) : {};
        return React.createElement(InputMask, _extends({
          mask: mask,
          formatChars: formatChars,
          value: inputValue || selectedValue,
          onChange: onChangeCallback,
          onFocus: onFocus,
          onBlur: onBlur,
          disabled: disabled || !1
        }, accessibleDisabledInputMaskProps), function () {
          return React.createElement("input", _extends({
            id: fieldUniqueId,
            "data-testid": testId || id,
            className: classes,
            "aria-autocomplete": ariaAutocomplete,
            "aria-label": _this2.translator(ariaLabel, {
              fieldLabel: _this2.translator(label)
            }),
            placeholder: _this2.getPlaceholder(placeholder),
            disabled: disabled || !1,
            type: "text"
          }, accessibleDisabledInputProps));
        });
      }
    }, {
      key: "renderControlReadOnly",
      value: function () {
        var _this$props3 = this.props,
          id = _this$props3.id,
          value = _this$props3.value,
          timeFormat = _this$props3.timeFormat,
          format = this.getTimeFormat(timeFormat).format,
          classes = cx(styles.readOnly);
        if (!value) return React.createElement(DateValue, _extends({
          id: id,
          className: classes,
          inputFormat: format
        }, this.generateReadOnlyAccessibilityProperties()));
        var hour = value.hour,
          minute = value.minute;
        return React.createElement(DateValue, _extends({
          id: id,
          className: classes,
          value: new Date(2e3, 0, 1, hour, minute),
          inputFormat: format,
          skipTimeZone: !0
        }, this.generateReadOnlyAccessibilityProperties()));
      }
    }, {
      key: "renderComponent",
      value: function (props, options) {
        var _this3 = this,
          id = props.id,
          value = props.value,
          min = props.min,
          max = props.max,
          disabled = props.disabled,
          timeFormat = props.timeFormat,
          _props$timeIntervals = props.timeIntervals,
          timeIntervals = void 0 === _props$timeIntervals ? 30 : _props$timeIntervals,
          isInitiallyOpen = props.isInitiallyOpen,
          controlClassName = props.controlClassName,
          format = this.getTimeFormat(timeFormat).format,
          selectedValue = this.parseValue(value),
          availableValues = generateTimeIntervals(timeIntervals || 30, format, min, max),
          accessibleDisabled = Boolean(disabled && isAccessibleDisabled("accessibleDisabled.fields")),
          internalControlClasses = cx(controlClassName, _defineProperty({}, styles.select, accessibleDisabled)),
          inputElement = React.createElement(GenericSelectControl, {
            id: id,
            value: selectedValue,
            availableValues: availableValues,
            allowNewValue: this.isValidOption,
            filter: filterOptions,
            getOptionLabel: function (option) {
              return option.label;
            },
            getOptionValue: function (option) {
              return option.value;
            },
            onValueChange: this.onValueChange,
            valid: options.isValid,
            disabled: disabled,
            components: {
              DropdownIndicator: function () {
                return null;
              },
              IndicatorSeparator: function () {
                return null;
              },
              Value: function () {
                return null;
              },
              Placeholder: function () {
                return null;
              },
              ClearIndicator: function () {
                return null;
              },
              Input: function (inputProps) {
                return _this3.renderInput(inputProps, accessibleDisabled);
              }
            },
            isInitiallyOpen: isInitiallyOpen,
            noOptionsMessage: null,
            createNewMessage: "{message}",
            controlShouldRenderValue: !1,
            backspaceRemovesValue: !1,
            isClearable: !0,
            internalClassNames: {
              control: internalControlClasses
            }
          }),
          icon = props.icon,
          iconPosition = props.iconPosition;
        return React.createElement(FieldIcon, {
          icon: icon,
          iconPosition: iconPosition,
          disabled: disabled
        }, inputElement);
      }
    }, {
      key: "renderControl",
      value: function (breakpointProps, options) {
        return this.renderComponent(breakpointProps, options);
      }
    }]), TimeFieldInternal;
  }();
export var TimeField = function (props) {
  var dateLocale = useContext(LocaleContext).dateLocale;
  return React.createElement(TimeFieldInternal, _extends({
    locale: dateLocale
  }, props));
};
TimeField.displayName = "TimeField", TimeField.propTypes = _objectSpread(_objectSpread(_objectSpread({}, FieldComponent.propTypes), FieldIcon.propTypes), {}, {
  value: PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired
  }),
  min: PropTypes.oneOfType([PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired
  }), PropTypes.bool]),
  max: PropTypes.oneOfType([PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired
  }), PropTypes.bool]),
  timeIntervals: PropTypes.number,
  timeFormat: PropTypes.oneOf(["12h", "24h"])
}), TimeField.defaultProps = _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  icon: "gw-access-time",
  iconPosition: "right",
  timeIntervals: 30
}), TimeField.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TimeField",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-access-time'",
        computed: !1
      },
      required: !1
    },
    iconPosition: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      required: !1
    },
    timeIntervals: {
      defaultValue: {
        value: "30",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Time intervals for input preselected values"
    },
    value: {
      type: {
        name: "shape",
        value: {
          hour: {
            name: "number",
            required: !0
          },
          minute: {
            name: "number",
            required: !0
          }
        }
      },
      required: !1,
      description: "Time to display"
    },
    min: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            hour: {
              name: "number",
              required: !0
            },
            minute: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Min time"
    },
    max: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            hour: {
              name: "number",
              required: !0
            },
            minute: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Max time"
    },
    timeFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'12h'",
          computed: !1
        }, {
          value: "'24h'",
          computed: !1
        }]
      },
      required: !1,
      description: "Time format"
    }
  },
  composes: ["../../FieldComponent/FieldComponent", "../../FieldComponent/FieldIcon"]
}, TimeField.__docgenInfo = {
  componentName: "TimeField",
  packageName: "@jutro/components",
  description: "",
  displayName: "TimeField",
  methods: [],
  actualName: "TimeField",
  props: {
    value: {
      type: {
        name: "shape",
        value: {
          hour: {
            name: "number",
            required: !0
          },
          minute: {
            name: "number",
            required: !0
          }
        }
      },
      required: !1,
      description: "Time to display"
    },
    min: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            hour: {
              name: "number",
              required: !0
            },
            minute: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Min time"
    },
    max: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            hour: {
              name: "number",
              required: !0
            },
            minute: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Max time"
    },
    timeIntervals: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Time intervals for input preselected values",
      defaultValue: {
        value: "30",
        computed: !1
      }
    },
    timeFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'12h'",
          computed: !1
        }, {
          value: "'24h'",
          computed: !1
        }]
      },
      required: !1,
      description: "Time format"
    },
    icon: {
      defaultValue: {
        value: "'gw-access-time'",
        computed: !1
      },
      required: !1
    },
    iconPosition: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../../FieldComponent/FieldComponent", "../../FieldComponent/FieldIcon"]
};
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import "core-js/modules/web.dom-collections.iterator.js";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useLayoutEffect, memo } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
var messagesLoaderPropTypes = {
    language: PropTypes.string.isRequired,
    loaders: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    onLoad: PropTypes.func.isRequired
  },
  MessagesLoaderInternal = function (_ref) {
    var _context3,
      language = _ref.language,
      loaders = _ref.loaders,
      onLoad = _ref.onLoad;
    return useLayoutEffect(function () {
      var _ref2,
        hasLanguageChanged = !1,
        load = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
          var _context, promises, messages;
          return _regeneratorRuntime.wrap(function (_context2) {
            for (;;) switch (_context2.prev = _context2.next) {
              case 0:
                return promises = _mapInstanceProperty(loaders).call(loaders, function (loader) {
                  return loader(language);
                }), _context2.t0 = _reduceInstanceProperty, _context2.next = 4, _Promise.all(promises);
              case 4:
                if (_context2.t1 = _context = _context2.sent, messages = (0, _context2.t0)(_context2.t1).call(_context, function (acc, next) {
                  return _Object$assign(acc, next);
                }, {}), !hasLanguageChanged) {
                  _context2.next = 8;
                  break;
                }
                return _context2.abrupt("return");
              case 8:
                onLoad(messages);
              case 9:
              case "end":
                return _context2.stop();
            }
          }, _callee);
        })), function () {
          return _ref2.apply(this, arguments);
        });
      return load(), function () {
        hasLanguageChanged = !0;
      };
    }, _concatInstanceProperty(_context3 = [language]).call(_context3, _toConsumableArray(loaders), [onLoad])), null;
  };
MessagesLoaderInternal.propTypes = messagesLoaderPropTypes, MessagesLoaderInternal.displayName = "MessagesLoader";
export var MessagesLoader = memo(MessagesLoaderInternal, isEqual);
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import "core-js/modules/es.string.replace.js";
import isFunction from "lodash/isFunction";
import isNaN from "lodash/isNaN";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
export function isEmptyValue(value) {
  return !isFunction(value) && (isNilValue(value) || 0 === value.length || isNaN(value));
}
export function isNilValue(value) {
  return null == value;
}
export function isValueSame(val1, val2) {
  return isEmptyValue(val1) && isEmptyValue(val2) || val1 === val2;
}
export function getValueForInput(fieldType, value) {
  return "number" === fieldType ? isNumber(value) ? Number(value) : "" : value || "";
}
export function isNumber(n) {
  var type = _typeof(n);
  if ("string" === type) {
    var original = trim(n);
    if (0 === (n = +n) && isEmpty(original)) return !1;
  } else if ("number" !== type) return !1;
  return n - n < 1;
}
export function isSafeNumber(number) {
  return number.replace(/[^0-9]/g, "") <= _Number$MAX_SAFE_INTEGER;
}
export function getDecimalRegex() {
  var decimals = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 2,
    withDecimalRegex = "^-?(\\s*|\\d+\\.?\\d{0,decimalPlaces})$".replace("decimalPlaces", "".concat(decimals));
  return decimals > 0 ? new RegExp(withDecimalRegex) : /^-?(\s*|\d+)$/;
}
export function truncateValue(value, decimalPlaces) {
  if (isNilValue(value)) return value;
  var decimalRegex = "^-?\\d+(?:\\.\\d{0,decimalPlaces})?".replace("decimalPlaces", "".concat(decimalPlaces)),
    truncatedValue = value.toString().match(new RegExp(decimalRegex));
  return isEmptyValue(value) || isEmptyValue(truncatedValue) ? value : _parseFloat(truncatedValue[0]);
}
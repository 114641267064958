import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["className", "id", "defaultCountry", "onChange", "dataType", "disabled", "required", "validateNumber", "value", "placeholder", "noDropdown", "testId", "inputFieldRef", "getInstance"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import "core-js/modules/es.string.replace.js";
import React, { useCallback, useEffect, useState } from "react";
import { DATA_TYPE_OBJECT } from "@jutro/prop-types";
import cx from "classnames";
import { useInitializeIntlPhoneNumber, useSetNumberOnBlur } from "./intlPhoneNumberHooks";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
import styles from "./IntlPhoneNumberField.module.css";
export var IntlPhoneNumberInput = function (_ref) {
  var className = _ref.className,
    id = _ref.id,
    defaultCountry = _ref.defaultCountry,
    onChange = _ref.onChange,
    dataType = _ref.dataType,
    disabled = _ref.disabled,
    required = _ref.required,
    validateNumber = _ref.validateNumber,
    value = _ref.value,
    placeholder = _ref.placeholder,
    noDropdown = _ref.noDropdown,
    testId = _ref.testId,
    inputFieldRef = _ref.inputFieldRef,
    getInstance = _ref.getInstance,
    restProps = _objectWithoutProperties(_ref, _excluded),
    classes = cx(styles.phoneNumber, className),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setIsFocused = _useState2[1];
  useInitializeIntlPhoneNumber(inputFieldRef, defaultCountry, disabled, placeholder, noDropdown);
  var getCountryDialCode = useCallback(function () {
    var _getInstance;
    return null === (_getInstance = getInstance()) || void 0 === _getInstance ? void 0 : _getInstance.selectedCountryData.dialCode;
  }, []);
  useSetNumberOnBlur(value, getInstance, isFocused), useEffect(function () {
    var _getInstance2, _getInstance3;
    validateNumber(null === (_getInstance2 = getInstance()) || void 0 === _getInstance2 ? void 0 : _getInstance2.isValidNumber(), null === (_getInstance3 = getInstance()) || void 0 === _getInstance3 ? void 0 : _getInstance3.getValidationError());
  }, [validateNumber, getInstance]);
  var getCountryIsoString = useCallback(function () {
      var _getInstance4;
      return null === (_getInstance4 = getInstance()) || void 0 === _getInstance4 ? void 0 : _getInstance4.selectedCountryData.iso2;
    }, []),
    getNumber = useCallback(function (format) {
      var _getInstance5;
      return null === (_getInstance5 = getInstance()) || void 0 === _getInstance5 ? void 0 : _getInstance5.getNumber(format);
    }, []),
    formatValue = useCallback(function () {
      var _context,
        countryCode = getCountryDialCode(),
        countryIsoString = getCountryIsoString(),
        phoneNumber = getNumber().replace("+".concat(countryCode), "");
      if (phoneNumber && countryIsoString) return dataType === DATA_TYPE_OBJECT ? {
        countryCode: {
          code: countryIsoString
        },
        phoneNumber: _trimInstanceProperty(phoneNumber).call(phoneNumber)
      } : _concatInstanceProperty(_context = "+".concat(countryCode)).call(_context, _trimInstanceProperty(phoneNumber).call(phoneNumber));
    }, [dataType, getCountryDialCode, getNumber, required, getCountryIsoString]),
    handleChange = function () {
      onChange(formatValue());
    },
    handleBlur = function () {
      var formattedPhoneInput;
      setIsFocused(!1), "string" == typeof (formattedPhoneInput = getNumber(1)) && getInstance().setNumber(formattedPhoneInput);
      var isValid = getInstance().isValidNumber(),
        validationError = getInstance().getValidationError();
      return onChange(formatValue()), noDropdown && void 0 === value ? validateNumber(!0) : getInstance().getNumber() ? validateNumber(isValid, validationError) : validateNumber(!0);
    },
    handleFocus = function () {
      setIsFocused(!0), validateNumber(!0);
    },
    accessibleDisabledProps = disabled && isAccessibleDisabled("accessibleDisabled.fields") ? _objectSpread(_objectSpread({}, muteCallbacks(_objectSpread(_objectSpread({}, restProps), {}, {
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur
    }))), {}, {
      "aria-disabled": !0,
      "aria-readonly": !0,
      readOnly: !0,
      disabled: void 0
    }) : {};
  return React.createElement("input", _extends({
    disabled: disabled,
    ref: inputFieldRef,
    className: classes,
    id: id,
    type: "tel",
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
    required: required,
    placeholder: placeholder,
    "data-testid": testId
  }, restProps, accessibleDisabledProps));
};
IntlPhoneNumberInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "IntlPhoneNumberInput"
}, IntlPhoneNumberInput.__docgenInfo = {
  componentName: "IntlPhoneNumberInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "IntlPhoneNumberInput",
  methods: [],
  actualName: "IntlPhoneNumberInput"
};
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _excluded = ["todayButtonText", "showCalendar", "icon", "containerClassName", "labelId", "openWithIconOnly", "testId", "id", "name", "minDate", "maxDate", "className", "calendarClassName", "popperClassName", "disabled", "startOpen", "onCalendarOpen", "onCalendarClose", "validationMessages", "isValid", "registerValidation", "hideTodayButton", "customInputProps", "renderCustomHeader", "inputFormat", "showMask", "noMask", "showMonthYearPicker"];
import "core-js/modules/es.string.replace.js";
import _flatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import cx from "classnames";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import DatePicker, { registerLocale } from "react-datepicker";
import defaultLocaleObject from "date-fns/locale/en-US";
import { uniqueInnerId } from "@jutro/platform";
import { getValidationMessageStyle } from "@jutro/validation/internal";
import { LocaleContext, isTodayOutOfRange, useTranslator } from "@jutro/locale";
import { PopperContainer } from "./PopperContainer";
import styles from "./GenericDatePicker.module.css";
import inputStyles from "../../InputField/InputField.module.css";
import { NextButton, PrevButton } from "./Header";
import { CustomInput } from "./CustomInput";
import { messages } from "./GenericDatePicker.messages";
import { Button } from "../../../button/Button";
var renderPopperContainer = function (calendarId, labelId) {
    return function (props) {
      return React.createElement(PopperContainer, _extends({}, props, {
        labelId: labelId,
        calendarId: calendarId
      }));
    };
  },
  renderDayContents = function (day) {
    return React.createElement("span", {
      className: styles.dayContent
    }, day, React.createElement("span", {
      className: styles.todayIndicator
    }));
  };
export var GenericDatePicker = React.forwardRef(function (props, ref) {
  var _cx2,
    _context,
    todayButtonText = props.todayButtonText,
    showCalendar = props.showCalendar,
    icon = props.icon,
    labelId = (props.containerClassName, props.labelId),
    openWithIconOnly = props.openWithIconOnly,
    testId = props.testId,
    id = props.id,
    name = props.name,
    minDate = props.minDate,
    maxDate = props.maxDate,
    className = props.className,
    calendarClassName = props.calendarClassName,
    popperClassName = props.popperClassName,
    disabled = props.disabled,
    startOpen = props.startOpen,
    _onCalendarOpen = props.onCalendarOpen,
    _onCalendarClose = props.onCalendarClose,
    validationMessages = props.validationMessages,
    isValid = props.isValid,
    registerValidation = props.registerValidation,
    hideTodayButton = props.hideTodayButton,
    customInputProps = props.customInputProps,
    renderCustomHeaderFn = props.renderCustomHeader,
    inputFormat = props.inputFormat,
    showMask = props.showMask,
    noMask = props.noMask,
    showMonthYearPicker = props.showMonthYearPicker,
    datePickerProps = _objectWithoutProperties(props, _excluded),
    translator = useTranslator(),
    dateLocale = useContext(LocaleContext).dateLocale;
  useEffect(function () {
    registerLocale(dateLocale.code || "en-US", isEmpty(dateLocale) ? defaultLocaleObject : dateLocale);
  }, [dateLocale]);
  var _useState = useState(startOpen),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setOpen = _useState2[1],
    calendarId = uniqueInnerId(null != id ? id : "", "calendarId").calendarId,
    datePickerAutoComplete = name ? "on" : "off",
    todayOutOfRange = isTodayOutOfRange({
      minDateObject: minDate,
      maxDateObject: maxDate
    }),
    popperClasses = cx(_defineProperty({}, styles.hidePopper, !showCalendar)),
    focusCalendar = useCallback(function (event) {
      event.preventDefault();
      var selectedNode = null == ref ? void 0 : ref.current.calendar.componentNode.querySelector('[tabindex="0"]');
      selectedNode && selectedNode.focus({
        preventScroll: !0
      });
    }, [ref]),
    handleKeyDown = useCallback(function (event) {
      var target = event.target;
      showCalendar && isOpen && "Tab" === event.key && target.id === id && focusCalendar(event);
    }, [isOpen, id, showCalendar, focusCalendar]),
    messageStyle = useMemo(function () {
      return getValidationMessageStyle(validationMessages);
    }, [validationMessages]),
    inputClasses = cx((_defineProperty(_cx2 = {
      disabled: disabled
    }, inputStyles.labpreviewBorder, !isUndefined(registerValidation) && !isValid), _defineProperty(_cx2, messageStyle, !isValid), _cx2), className),
    renderCustomHeader = renderCustomHeaderFn && function (headerProps) {
      return renderCustomHeaderFn(_objectSpread(_objectSpread({}, headerProps), {}, {
        PrevButtonComponent: PrevButton,
        NextButtonComponent: NextButton
      }));
    },
    _flat = _flatInstanceProperty(_context = [inputFormat]).call(_context),
    firstDateFormat = _slicedToArray(_flat, 1)[0],
    normalisedDateFormat = useMemo(function () {
      return firstDateFormat && (mask = firstDateFormat, charsCounter = _reduceInstanceProperty(lookFor = ["d", "D", "m", "M"]).call(lookFor, function (map, currentChar) {
        return _objectSpread(_objectSpread({}, map), {}, _defineProperty({}, currentChar, 0));
      }, {}), mask.split("").forEach(function (char) {
        _includesInstanceProperty(lookFor).call(lookFor, char) && (charsCounter[char] += 1);
      }), _reduceInstanceProperty(_context2 = _filterInstanceProperty(lookFor).call(lookFor, function (letter) {
        return 1 === charsCounter[letter];
      })).call(_context2, function (newMask, letter) {
        var _context3;
        return newMask.replace(letter, _concatInstanceProperty(_context3 = "".concat(letter)).call(_context3, letter));
      }, mask));
      var mask, _context2, lookFor, charsCounter;
    }, [firstDateFormat]);
  return React.createElement("div", {
    className: styles.datePicker,
    "aria-expanded": !!isOpen,
    "aria-haspopup": "true",
    "aria-controls": calendarId,
    role: "combobox"
  }, React.createElement(DatePicker, _extends({
    ref: ref,
    onKeyDown: handleKeyDown,
    autoComplete: datePickerAutoComplete,
    todayButton: !hideTodayButton && React.createElement(Button, {
      className: styles.todayButton,
      disabled: todayOutOfRange,
      type: "text"
    }, translator(todayButtonText || messages.today)),
    popperContainer: renderPopperContainer(calendarId, labelId),
    id: id,
    name: name,
    open: isOpen,
    minDate: minDate,
    maxDate: maxDate,
    disabled: disabled,
    customInput: React.createElement(CustomInput, _extends({
      noMask: noMask,
      showMask: normalisedDateFormat && showMask,
      mask: normalisedDateFormat,
      icon: icon,
      showCalendar: showCalendar,
      openWithIconOnly: openWithIconOnly,
      focusCalendar: focusCalendar,
      "data-testid": testId || id,
      disabled: disabled
    }, customInputProps)),
    dateFormat: showMask ? normalisedDateFormat : firstDateFormat
  }, datePickerProps, {
    onCalendarOpen: function () {
      setOpen(!0), null == _onCalendarOpen || _onCalendarOpen();
    },
    onCalendarClose: function () {
      setOpen(!1), null == _onCalendarClose || _onCalendarClose();
    },
    className: inputClasses,
    calendarClassName: cx(calendarClassName, _defineProperty({}, styles.monthYearPicker, showMonthYearPicker)),
    popperClassName: cx(popperClasses, popperClassName),
    renderCustomHeader: renderCustomHeader,
    renderDayContents: renderDayContents,
    showMonthYearPicker: showMonthYearPicker
  })));
});
GenericDatePicker.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericDatePicker"
}, renderDayContents.__docgenInfo = {
  componentName: "renderDayContents",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderDayContents",
  methods: [],
  actualName: "renderDayContents"
};
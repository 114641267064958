import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import "core-js/modules/es.string.replace.js";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { dataTypeShape, availableValueObjectShape } from "@jutro/prop-types";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { CheckboxField } from "../CheckboxField/CheckboxField";
import styles from "./CheckboxGroupField.module.css";
export var CheckboxGroupField = function (_FieldComponent) {
  _inherits(CheckboxGroupField, FieldComponent);
  var _super = _createSuper(CheckboxGroupField);
  function CheckboxGroupField() {
    var _context, _this;
    _classCallCheck(this, CheckboxGroupField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "handleValueChange", function (code) {
      var value = _this.props.value ? _toConsumableArray(_this.props.value) : [];
      if (_includesInstanceProperty(value).call(value, code)) {
        var index = _indexOfInstanceProperty(value).call(value, code);
        _spliceInstanceProperty(value).call(value, index, 1);
      } else value.push(code);
      _this.notifyChange(value);
    }), _this;
  }
  return _createClass(CheckboxGroupField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderLabel",
    value: function (props) {
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "renderLabel", this).call(this, _objectSpread(_objectSpread({}, props), {}, {
        labelContainerClassName: styles.fieldLabelContainer
      }));
    }
  }, {
    key: "renderTooltipIcon",
    value: function (props) {
      var _tooltip$text,
        tooltip = this.props.tooltip,
        tooltipText = null !== (_tooltip$text = null == tooltip ? void 0 : tooltip.text) && void 0 !== _tooltip$text ? _tooltip$text : tooltip,
        tooltipClassName = null == tooltip ? void 0 : tooltip.className,
        newProps = _objectSpread(_objectSpread({}, props), {}, {
          tooltip: _objectSpread(_objectSpread({}, tooltip), {}, {
            text: tooltipText,
            className: cx(tooltipClassName, styles.tooltip)
          })
        });
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "renderTooltipIcon", this).call(this, newProps);
    }
  }, {
    key: "renderCheckboxValues",
    value: function (props, values) {
      var _this2 = this,
        disabled = props.disabled,
        value = props.value,
        controlClassName = props.controlClassName,
        classes = cx(styles.checkboxGroup);
      return _mapInstanceProperty(values).call(values, function (_ref) {
        var code = _ref.code,
          name = _ref.name,
          subtitle = _ref.subtitle,
          secondaryLabel = _ref.secondaryLabel,
          isCheckedVal = value && _includesInstanceProperty(value).call(value, code),
          secondaryLabelId = "secondary-label-".concat(code.toString().replace(/\s/g, "_"));
        return React.createElement(CheckboxField, {
          id: code,
          label: name,
          secondaryLabel: subtitle || secondaryLabel,
          key: code,
          className: classes,
          showInlineLabel: !0,
          required: !1,
          disabled: disabled,
          value: isCheckedVal,
          onValueChange: function () {
            return _this2.handleValueChange(code);
          },
          labelContainerClassName: styles.labelContainer,
          secondaryLabelId: secondaryLabelId,
          controlClassName: controlClassName
        });
      });
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function (props) {
      var translator = this.translator,
        label = props.label;
      return _objectSpread(_objectSpread({}, _get(_getPrototypeOf(CheckboxGroupField.prototype), "generateAccessibilityProperties", this).call(this)), {}, {
        "aria-label": translator(label)
      });
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        isHorizontal = breakpointProps.isHorizontal,
        checkboxes = this.renderCheckboxValues(breakpointProps, availableValues),
        classes = cx(_defineProperty({}, styles.horizontal, isHorizontal)),
        a11yProps = _objectSpread(_objectSpread({}, this.generateAccessibilityProperties(breakpointProps)), {}, {
          "aria-required": void 0
        });
      return React.createElement("div", _extends({
        id: id,
        className: classes
      }, a11yProps, {
        role: "group"
      }), checkboxes);
    }
  }]), CheckboxGroupField;
}();
_defineProperty(CheckboxGroupField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  value: PropTypes.arrayOf(PropTypes.string),
  dataType: dataTypeShape,
  isHorizontal: PropTypes.bool
})), _defineProperty(CheckboxGroupField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  availableValues: [],
  isHorizontal: !1
})), CheckboxGroupField.__docgenInfo = {
  description: "@typedef {typeof CheckboxGroupField.propTypes} CheckboxGroupFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<CheckboxGroupFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "handleValueChange",
    docblock: "Handles value change\n@param {string} code",
    modifiers: [],
    params: [{
      name: "code",
      description: null,
      type: {
        name: "string"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      type: null
    }],
    returns: null
  }, {
    name: "renderCheckboxValues",
    docblock: "Render the checkboxes for the specified values\n\n@param {object} props\n@param {Array<any>} values - values to render\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "values",
      description: "values to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the checkboxes for the specified values"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@param {object} props\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "CheckboxGroupField",
  props: {
    availableValues: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "availableValueObjectShape"
        }
      },
      required: !1,
      description: "Array of available checkboxes"
    },
    isHorizontal: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component will be horizontally oriented"
    },
    value: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "List of codes of the selected checkboxes."
    },
    dataType: {
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the items in the value array"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, CheckboxGroupField.__docgenInfo = {
  componentName: "CheckboxGroupField",
  packageName: "@jutro/components",
  description: "",
  displayName: "CheckboxGroupField",
  methods: [{
    name: "handleValueChange",
    docblock: "Handles value change\n@param {string} code",
    modifiers: [],
    params: [{
      name: "code",
      description: null,
      type: {
        name: "string"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderCheckboxValues",
    docblock: "Render the checkboxes for the specified values\n\n@param {object} props\n@param {Array<any>} values - values to render\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "values",
      description: "values to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the checkboxes for the specified values"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@param {object} props\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "CheckboxGroupField",
  metadataType: "field",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Array of available checkboxes",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    value: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "List of codes of the selected checkboxes."
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the items in the value array"
    },
    isHorizontal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component will be horizontally oriented",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};